import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import userService from "../../services/UserService";
import { toast } from "react-toastify";
import benefitService from "../../services/BenefitService";
import { getTokenAndId, Handle401 } from "../../services/helperService";
import Spinner from "react-bootstrap/Spinner";
import "./Login.scss";
import jwt_decode from "jwt-decode";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [validate, setValidate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mainLoader, setMainLoader] = useState(false);
  let navigate = useNavigate();

  useEffect(() => {
    (async function () {
      try {
        let redirectUrl = localStorage.getItem("url");
        setMainLoader(true);
        let { token, bpgId, url } = getTokenAndId();
        redirectUrl = redirectUrl ? redirectUrl : url;
        if (token) {
          if (bpgId) {
            await getPlanner(token, bpgId, false)
              .then(async ({ data }) => {
                if (data) {
                  localStorage.setItem("token", token);
                  var decodedToken = jwt_decode(token);
                  localStorage.setItem(
                    "bpg_access",
                    decodedToken.data.bpg_access
                  );
                  localStorage.setItem(
                    "access",
                    JSON.stringify(decodedToken.data)
                  );

                  toast.success("Logged in successfully.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                  setMainLoader(false);
                  const path = localStorage.getItem("url");
                  navigate(path ? path : `/bpg/${bpgId}/welcome`);
                } else {
                  setMainLoader(false);

                  toast.error("Token is invalid or Expired.", {
                    position: toast.POSITION.BOTTOM_RIGHT,
                  });
                }
              })
              .catch((error) => {
                console.log("--error-- ", error);
                setMainLoader(false);
                toast.error("Token is invalid or Expired.", {
                  position: toast.POSITION.BOTTOM_RIGHT,
                });
              });
          } else if (redirectUrl) {
            localStorage.setItem("token", token);
            var decodedToken = jwt_decode(token);
            localStorage.setItem("bpg_access", decodedToken.data.bpg_access);
            localStorage.setItem("access", JSON.stringify(decodedToken.data));
            window.location.href = redirectUrl;
          } else {
            window.location.href = `https://myagentcrm.com/crm/bpg-manager/`;
          }
        } else {
          setMainLoader(false);
          let isAuthenticated = userService.isLoggedIn();
          if (isAuthenticated) {
            let path = redirectUrl ? redirectUrl : "";
            if (!path) {
              let { bpgId } = getTokenAndId();
              if (bpgId) {
                path = `/bpg/${bpgId}/welcome`;
                navigate(path);
              }
            }
          }
        }
      } catch (e) {
        console.error(e);
        setMainLoader(false);
      }
    })();
  }, []);

  const getPlanner = async (token, bpgId) => {
    const plandata = await benefitService.getPlanner(bpgId, token);
    if (plandata && plandata["data"] && plandata["data"]["data"]) {
      let plan = plandata && plandata["data"] && plandata["data"]["data"];
      // await getUserDetails(plan && plan["profileid"], token);
      localStorage.setItem("benefitPlan", JSON.stringify(plan));
      window.dispatchEvent(new Event("storage"));
    }
    return plandata;
  };

  // const getUserDetails = async (id, token) => {
  //   if (id) {
  //     const {
  //       data: { data },
  //     } = await userService.getUser(id, token);

  //     setLoading(false);
  //     if (data) {
  //       localStorage.setItem("user", JSON.stringify(data));
  //     }
  //   }
  // };

  const submit = async (event) => {
    setLoading(true);
    try {
      event.preventDefault();
      event.stopPropagation();
      const form = event.currentTarget;
      setValidate(false);
      if (form.checkValidity() === false) {
        setLoading(false);
        return setValidate(true);
      }

      let token = await userService.login(email, password);

      if (token) {
        var decodedToken = jwt_decode(token);
        token = token.replace("Bearer ", "");
        localStorage.setItem("access", JSON.stringify(decodedToken.data));
        let { bpgId } = getTokenAndId();
        const path = localStorage.getItem("url");
        if (bpgId) {
          await getPlanner(token, bpgId);
          navigate(path ? path : `/bpg/${bpgId}/welcome`);
        } else {
          window.location.href = `https://myagentcrm.com/crm/bpg-manager/`;
        }

        setLoading(false);
        toast.success("Logged in successfully", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      } else {
        setLoading(false);
        toast.error("Please check login credantials", {
          position: toast.POSITION.BOTTOM_RIGHT,
        });
      }
    } catch (error) {
      setLoading(false);
      console.log("error=> ", error);
      Handle401(error);
    }
  };

  return (
    <>
      {mainLoader && (
        <div className="loader-center">
          <Spinner animation="border" variant="primary" />
        </div>
      )}
      {!mainLoader && (
        <div className="w-100">
          <Row className="pt-2 ">
            <Col
              md={{ span: 6, offset: 3 }}
              className="d-flex justify-content-center"
            >
              <img
                className=""
                src="https://mfbcrm2dev.wpengine.com/wp-content/themes/mfb/images/myagentcrm-logo-sm.jpg"
                alt="MyAgentCRM"
              />
            </Col>
            <Col md={{ span: 4, offset: 4 }} className="mt-5">
              <div className="panel panel-default mx-5">
                <div className="panel-body">
                  <Form noValidate validated={validate} onSubmit={submit}>
                    <div className="form-group">
                      <label for="email" className="text-label">
                        Email
                      </label>
                      <Form.Control
                        type="text"
                        className="form-control con valid"
                        name="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        placeholder="Email"
                        autoComplete="off"
                        required
                      />
                      {validate && !email && (
                        <div>
                          <label id="email-error" className="error">
                            This field is required.
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="form-group">
                      <label for="password" className="text-label">
                        Password
                      </label>
                      <Form.Control
                        type="password"
                        name="password"
                        className="form-control con valid"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Password"
                        autoComplete="off"
                        required
                      />
                      {validate && !password && (
                        <div>
                          <label id="email-error" className="error">
                            This field is required.
                          </label>
                        </div>
                      )}
                    </div>
                    <div className="form-group d-flex pl-2">
                      <Form.Check
                        id="rememberMe"
                        type="checkbox"
                        name="rem"
                        label="Remember me"
                      />
                    </div>
                    <div className="form-group">
                      <button
                        type="submit"
                        className="submit-button w-100 c-pointer"
                        disabled={loading}
                      >
                        {loading && (
                          <Spinner
                            animation="border"
                            className="button-loader"
                          />
                        )}{" "}
                        &nbsp; Login
                      </button>
                    </div>
                    <div className="reset-text mb-3">
                      <a href="javascript:void(0)" id="actionFormPass">
                        Reset password
                      </a>
                    </div>
                  </Form>
                </div>
              </div>
            </Col>
          </Row>
          <div id="footer" style={{ bottom: 0 }}>
            <p className="copy">
              © Copyright MyAgentCRM. All rights reserved.{" "}
              <a href="https://myagentcrm.com/privacy/" itemprop="item">
                <span itemprop="name">Privacy Policy</span>
              </a>{" "}
              |{" "}
              <a href="https://myagentcrm.com/terms/" itemprop="item">
                <span itemprop="name">Terms of Use</span>
              </a>
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
